

















































































































































































































































































































































































































































































































































import { Vue, Component, Watch } from 'vue-property-decorator';
import { loadingApply, getCodeList } from '@/api/home';
import FreeTrialDlg from './components/FreeTrialDlg.vue';
import Header from './components/Header.vue';
import utils from '@/utils/index';
import SimpleForm from './components/SimpleForm.vue';
import common from '@/utils/common';
import { EGGAlaiModule } from '@/store/modules/EGGAlai';

const sourceMap: string = process.env.NODE_ENV ? process.env.NODE_ENV : 'dev';

@Component({
    name: 'EGGAlaiHome',
    components: {
        FreeTrialDlg,
        Header,
        SimpleForm
    }
})
export default class EGGAlaiHome extends Vue {
    private topForm = EGGAlaiModule.topForm;
    private bottomForm = EGGAlaiModule.bottomForm;
    private locale = 'EN';
    private showFreeTrailDlg = false;
    private showSuccessDlg = true;
    private freeTrialForm:any = {};

    private domainName:any = {
        development: {
            url: 'http://seller.test.jimuitech.com/#/home/login'
        },
        dev: {
            url: 'http://seller.test.jimuitech.com/#/home/login'
        },
        test: {
            url: 'http://seller.test.jimuitech.com/#/home/login'
        },
        uat: {
            url: 'http://seller.uat.jimuitech.com/#/home/login'
        },
        stable: {
            url: 'http://seller.stable.jimuitech.com/#/home/login'
        },
        production: {
            url: 'https://seller.eggmalltech.com/#/home/login'
        }
    }

    private aotoplayOne = false;

    private aotoplayTwo = false;

    private aotoplayThree = false;

    private aotoplayFour = false;

    private aotoplayFive = false;

    partOneStyle = false;

    partTwoStyle = false;

    partThreeStyle = false;

    partFourStyle = false;

    partTwoOneStyle = false;

    partTwoTwoStyle = false;

    partTwoThreeStyle = false;

    partTwoFourStyle = false;

    partThreeOneStyle = false;

    partThreeTwoStyle = false;

    partThreeThreeStyle = false;

    partThreeFourStyle = false;

    partFourOneStyle = false;

    partFourTwoStyle = false;

    partFourThreeStyle = false;

    partFourFourStyle = false;

    partFiveOneStyle = false;

    partFiveTwoStyle = false;

    partFiveThreeStyle = false;

    partFiveFourStyle = false;

    isEndOne = false;

    numOne = 0;

    isEndTwo = false;

    numTwo = 0;

    isEndThree = false;

    numThree = 0;

    isEndFour = false;

    numFour = 0;

    isEndFive = false;

    numFive = 0;

    isPlay = true;

    showVideoBtn = false;

    showInterviewBtn = false;

    private partOneSrc = 'https://image-cdn.eggmalltech.com/eggPos/vedio/Topic%201%20Product%20Search%20by%20VIN%20No.%20or%20Vehicle%20Type.mp4';

    private partTwoSrc = 'https://image-cdn.eggmalltech.com/eggPos/vedio/Topic%205%20Customer%20Management.mp4';

    private partThreeSrc = 'https://image-cdn.eggmalltech.com/eggPos/vedio/Topic%209%20Purchase%20Process.mp4';

    private partFourSrc = 'https://image-cdn.eggmalltech.com/eggPos/vedio/Topic%2013%20Business%20Overview%20Dashboard.mp4';

    private partFiveSrc = 'https://image-cdn.eggmalltech.com/eggPos/vedio/Topic%2017%20Connect%20to%20other%20Marketplace%20(Lazada,%20Shopee...).mp4';

    private handleFreeTrial() {
        this.showFreeTrailDlg = true;
        loadingApply({ country: '1001' });
        window.gtag('event', 'free_trial');
        utils.fbq('trackCustom', 'free_trial');
    }

    nextVedio(num: any) {
        if (num === 1) {
            this.partOneClick(this.numOne + 1);
        }
        if (num === 2) {
            this.partTwoClick(this.numTwo + 1);
        }
        if (num === 3) {
            this.partThreeClick(this.numThree + 1);
        }
        if (num === 4) {
            this.partFourClick(this.numFour + 1);
        }
        if (num === 5) {
            this.partFiveClick(this.numFive + 1);
        }
    }

    replayVedio(num: any) {
        if (num === 1) {
            this.partOneClick(this.numOne);
        }
        if (num === 2) {
            this.partTwoClick(this.numTwo);
        }
        if (num === 3) {
            this.partThreeClick(this.numThree);
        }
        if (num === 4) {
            this.partFourClick(this.numFour);
        }
        if (num === 5) {
            this.partFiveClick(this.numFive);
        }
    }

    private partOneClick(num: any) {
        this.numOne = num;
        this.isEndOne = false;
        if (num === 1) {
            this.partOneStyle = true;
            this.partTwoStyle = false;
            this.partThreeStyle = false;
            this.partFourStyle = false;
            this.partOneSrc = 'https://image-cdn.eggmalltech.com/eggPos/vedio/Topic%201%20Product%20Search%20by%20VIN%20No.%20or%20Vehicle%20Type.mp4';
        } else if (num === 2) {
            this.partOneStyle = false;
            this.partTwoStyle = true;
            this.partThreeStyle = false;
            this.partFourStyle = false;
            this.partOneSrc = 'https://image-cdn.eggmalltech.com/eggPos/vedio/Topic%202%20Auto%20Part%20Data%20and%20Price%20List%20Available.mp4';
        } else if (num === 3) {
            this.partOneStyle = false;
            this.partTwoStyle = false;
            this.partThreeStyle = true;
            this.partFourStyle = false;
            this.partOneSrc = 'https://image-cdn.eggmalltech.com/eggPos/vedio/Topic%203%20Customer%20Credit%20Management.mp4';
        } else {
            this.partOneStyle = false;
            this.partTwoStyle = false;
            this.partThreeStyle = false;
            this.partFourStyle = true;
            this.partOneSrc = 'https://image-cdn.eggmalltech.com/eggPos/vedio/Topic%204%20Smart%20Scan%20Feature.mp4';
        }
        this.isPlay = false;
        this.aotoplayOne = true;
        this.$nextTick(() => {
            (this.$refs.vedioOne as any).play();
            const aud: any = this.$refs.vedioOne;
            aud.onended = () => {
                this.isEndOne = true;
            };
        });
    }

    private partTwoClick(num: any) {
        this.isEndTwo = false;
        this.numTwo = num;
        if (num === 1) {
            this.partTwoOneStyle = true;
            this.partTwoTwoStyle = false;
            this.partTwoThreeStyle = false;
            this.partTwoFourStyle = false;
            this.partTwoSrc = 'https://image-cdn.eggmalltech.com/eggPos/vedio/Topic%205%20Customer%20Management.mp4';
        } else if (num === 2) {
            this.partTwoOneStyle = false;
            this.partTwoTwoStyle = true;
            this.partTwoThreeStyle = false;
            this.partTwoFourStyle = false;
            this.partTwoSrc = 'https://image-cdn.eggmalltech.com/eggPos/vedio/Topic%206%20Mobile%20CRM.mp4';
        } else if (num === 3) {
            this.partTwoOneStyle = false;
            this.partTwoTwoStyle = false;
            this.partTwoThreeStyle = true;
            this.partTwoFourStyle = false;
            this.partTwoSrc = 'https://image-cdn.eggmalltech.com/eggPos/vedio/Topic%207%20Onsite%20Ordering%20during%20Sales%20Visiting.mp4';
        } else {
            this.partTwoOneStyle = false;
            this.partTwoTwoStyle = false;
            this.partTwoThreeStyle = false;
            this.partTwoFourStyle = true;
            this.partTwoSrc = 'https://image-cdn.eggmalltech.com/eggPos/vedio/Topic%208%20Sales%20Force%20Management.mp4';
        }
        this.aotoplayTwo = true;
        this.isPlay = false;
        this.$nextTick(() => {
            (this.$refs.vedioTwo as any).play();
            const aud: any = this.$refs.vedioTwo;
            aud.onended = () => {
                this.isEndTwo = true;
            };
        });
    }

    private partThreeClick(num: any) {
        this.isEndThree = false;
        this.numThree = num;
        if (num === 1) {
            this.partThreeOneStyle = true;
            this.partThreeTwoStyle = false;
            this.partThreeThreeStyle = false;
            this.partThreeFourStyle = false;
            this.partThreeSrc = 'https://image-cdn.eggmalltech.com/eggPos/vedio/Topic%209%20Purchase%20Process.mp4';
        } else if (num === 2) {
            this.partThreeOneStyle = false;
            this.partThreeTwoStyle = true;
            this.partThreeThreeStyle = false;
            this.partThreeFourStyle = false;
            this.partThreeSrc = 'https://image-cdn.eggmalltech.com/eggPos/vedio/Topic%2010%20Inventory%20Management.mp4';
        } else if (num === 3) {
            this.partThreeOneStyle = false;
            this.partThreeTwoStyle = false;
            this.partThreeThreeStyle = true;
            this.partThreeFourStyle = false;
            this.partThreeSrc = 'https://image-cdn.eggmalltech.com/eggPos/vedio/Topic%2011%203PL%20logistic%20status%20check.mp4';
        } else {
            this.partThreeOneStyle = false;
            this.partThreeTwoStyle = false;
            this.partThreeThreeStyle = false;
            this.partThreeFourStyle = true;
            this.partThreeSrc = 'https://image-cdn.eggmalltech.com/eggPos/vedio/Topic%2012%203PL%20logistic%20schedule%20booking.mp4';
        }
        this.aotoplayThree = true;
        this.isPlay = false;
        this.$nextTick(() => {
            (this.$refs.vedioThree as any).play();
            const aud: any = this.$refs.vedioThree;
            aud.onended = () => {
                this.isEndThree = true;
            };
        });
    }

    private partFourClick(num: any) {
        this.isEndFour = false;
        this.numFour = num;
        if (num === 1) {
            this.partFourOneStyle = true;
            this.partFourTwoStyle = false;
            this.partFourThreeStyle = false;
            this.partFourFourStyle = false;
            this.partFourSrc = 'https://image-cdn.eggmalltech.com/eggPos/vedio/Topic%2013%20Business%20Overview%20Dashboard.mp4';
        } else if (num === 2) {
            this.partFourOneStyle = false;
            this.partFourTwoStyle = true;
            this.partFourThreeStyle = false;
            this.partFourFourStyle = false;
            this.partFourSrc = 'https://image-cdn.eggmalltech.com/eggPos/vedio/Topic%2014%20Profit%20&%20Loss%20Report.mp4';
        } else if (num === 3) {
            this.partFourOneStyle = false;
            this.partFourTwoStyle = false;
            this.partFourThreeStyle = true;
            this.partFourFourStyle = false;
            this.partFourSrc = 'https://image-cdn.eggmalltech.com/eggPos/vedio/Topic%2015%20Create%20Collections%20Report.mp4';
        } else {
            this.partFourOneStyle = false;
            this.partFourTwoStyle = false;
            this.partFourThreeStyle = false;
            this.partFourFourStyle = true;
            this.partFourSrc = 'https://image-cdn.eggmalltech.com/eggPos/vedio/Topic%2016%20Generate%20Business%20&%20Tax%20Reports.mp4';
        }
        this.aotoplayFour = true;
        this.isPlay = false;
        this.$nextTick(() => {
            (this.$refs.vedioFour as any).play();
            const aud: any = this.$refs.vedioFour;
            aud.onended = () => {
                this.isEndFour = true;
            };
        });
    }

    private partFiveClick(num: any) {
        this.isEndFive = false;
        this.numFive = num;
        if (num === 1) {
            this.partFiveOneStyle = true;
            this.partFiveTwoStyle = false;
            this.partFiveThreeStyle = false;
            this.partFiveFourStyle = false;
            this.partFiveSrc = 'https://image-cdn.eggmalltech.com/eggPos/vedio/Topic%2017%20Connect%20to%20other%20Marketplace%20(Lazada,%20Shopee...).mp4';
        } else if (num === 2) {
            this.partFiveOneStyle = false;
            this.partFiveTwoStyle = true;
            this.partFiveThreeStyle = false;
            this.partFiveFourStyle = false;
            this.partFiveSrc = 'https://image-cdn.eggmalltech.com/eggPos/vedio/Topic%2018%20Customer%20order%20via%20APP%20or%20Web.mp4';
        } else if (num === 3) {
            this.partFiveOneStyle = false;
            this.partFiveTwoStyle = false;
            this.partFiveThreeStyle = true;
            this.partFiveFourStyle = false;
            this.partFiveSrc = 'https://image-cdn.eggmalltech.com/eggPos/vedio/Topic%2019%20Payment%20gateway%20connection.mp4';
        } else {
            this.partFiveOneStyle = false;
            this.partFiveTwoStyle = false;
            this.partFiveThreeStyle = false;
            this.partFiveFourStyle = true;
            this.partFiveSrc = 'https://image-cdn.eggmalltech.com/eggPos/vedio/Topic%2020%20Facebook%20ads%20and%20google%20ads%20monthly%20campaign%20management.mp4';
        }
        this.aotoplayFive = true;
        this.isPlay = false;
        this.$nextTick(() => {
            (this.$refs.vedioFive as any).play();
            const aud: any = this.$refs.vedioFive;
            aud.onended = () => {
                this.isEndFive = true;
            };
        });
    }

    private handleLogin() {
        utils.openUrl(`${this.domainName[sourceMap].url}`);
    }

    private backToTop() {
        document.documentElement.scrollTop = 0;
        document.body.scrollTop = 0;
    }

    @Watch('$i18n.locale')
    watchLocaleChange(val: any) {
        let video: any = '';
        if (val === 'th') video = document.getElementById('videoTH');
        else video = document.getElementById('videoEN');
        const videoSrc = video.currentSrc;
        video.src = '';
        video.src = videoSrc;
        this.showVideoBtn = false;
    }

    private mounted() {
        const videoTH: any = document.getElementById('videoTH');
        const videoEN: any = document.getElementById('videoEN');
        const videoInterviewEN:any = document.getElementById('videoInterviewEN');
        const videoInterviewTH:any = document.getElementById('videoInterviewTH');
        this.addVideoListener(videoTH, 'intro');
        this.addVideoListener(videoEN, 'intro');
        this.addVideoListener(videoInterviewTH, 'interview');
        this.addVideoListener(videoInterviewEN, 'interview');

        // videoTH.addEventListener('ended', () => {
        //     videoTH.load();
        //     this.showVideoBtn = false;
        // }, false);
        // videoTH.addEventListener('play', () => {
        //     this.showVideoBtn = false;
        // });
        // videoTH.addEventListener('pause', () => {
        //     this.showVideoBtn = true;
        // });
        // videoTH.addEventListener('seeking', () => { // 开始移动进度条
        //     this.showVideoBtn = false;
        // });
        // videoTH.addEventListener('seeked', () => { // 进度条已经移动到了新的位置
        //     videoTH.play();
        // });

        // videoEN.addEventListener('ended', () => {
        //     videoEN.load();
        //     this.showVideoBtn = false;
        // }, false);
        // videoEN.addEventListener('play', () => {
        //     this.showVideoBtn = false;
        // });
        // videoEN.addEventListener('pause', () => {
        //     this.showVideoBtn = true;
        // });
        // videoEN.addEventListener('seeking', () => { // 开始移动进度条
        //     this.showVideoBtn = false;
        // });
        // videoEN.addEventListener('seeked', () => { // 进度条已经移动到了新的位置
        //     videoEN.play();
        // });
    }

    private addVideoListener(el:any, btnType:string) {
        el.addEventListener('ended', () => {
            el.load();
            if (btnType === 'interview') {
                this.showInterviewBtn = false;
            } else {
                this.showVideoBtn = false;
            }
        }, false);
        el.addEventListener('play', () => {
            if (btnType === 'interview') {
                this.showInterviewBtn = false;
            } else {
                this.showVideoBtn = false;
            }
        });
        el.addEventListener('pause', () => {
            if (btnType === 'interview') {
                this.showInterviewBtn = true;
            } else {
                this.showVideoBtn = true;
            }
        });
        el.addEventListener('seeking', () => { // 开始移动进度条
            if (btnType === 'interview') {
                this.showInterviewBtn = false;
            } else {
                this.showVideoBtn = false;
            }
        });
        el.addEventListener('seeked', () => { // 进度条已经移动到了新的位置
            el.play();
        });
    }
}
